import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { TaxonomyType } from "data/types";
import React, {FC, useEffect, useState} from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet-async";
import SectionHero from "../../components/HomeSearchBlock/HomeSearchBlock";
import SectionOurFeatures from "../../components/SectionOurFeatures/SectionOurFeatures";
import SectionHowItWork from "../../components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "../../components/BackgroundSection/BackgroundSection";
import SectionBecomeAnAuthor from "../../components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionClientSay from "../../components/SectionClientSay/SectionClientSay";
import HomeSearchBlock from "../../components/HomeSearchBlock/HomeSearchBlock";
import {useLocation} from "react-router-dom";
import {searchFlights} from "../../services/search.service";
import {formatDate} from "../../utils/dateFormat";

export interface ListingFlightsPageProps {
  className?: string;
}

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "#",
    name: "Enjoy the Beauty of Brazil ",
    taxonomy: "category",
    count: 17288,
    thumbnail:
      "https://images.pexels.com/photos/1118877/pexels-photo-1118877.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
    listingType: "experiences",
  },
  {
    id: "2",
    href: "#",
    name: "Enjoy the Beauty of Paris",
    taxonomy: "category",
    count: 2118,
    thumbnail:
      "https://images.pexels.com/photos/2412609/pexels-photo-2412609.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    listingType: "experiences",
  },
  {
    id: "3",
    href: "#",
    name: "Enjoy the Beauty of Bangkok",
    taxonomy: "category",
    count: 36612,
    thumbnail:
      "https://images.pexels.com/photos/732895/pexels-photo-732895.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    listingType: "experiences",
  },
  {
    id: "5",
    href: "#",
    name: "Enjoy the Beauty of Singapore",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/3152124/pexels-photo-3152124.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
    listingType: "experiences",
  },
  {
    id: "4",
    href: "#",
    name: "Enjoy the Beauty of Seoul",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/373290/pexels-photo-373290.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    listingType: "experiences",
  },
];

const useQueryParams = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  return {
    originLocation: queryParams.get("originLocation"),
    originCity: queryParams.get("originCity"),
    destinationLocation: queryParams.get("destinationLocation"),
    destinationCity: queryParams.get("destinationCity"),
    startDate: queryParams.get("startDate"),
    endDate: queryParams.get("endDate"),
  };
};

const SearchPage: FC<ListingFlightsPageProps> = ({
  className = "",
}) => {

  const { originLocation, originCity, destinationLocation, destinationCity, startDate, endDate } = useQueryParams();

  const [searchData, setSearchData] = useState<any>(null);

  const [loading, setLoading] = useState(true);

  const search = () => {

    setLoading(true);
    const searchData = {
      origin: originLocation,
        destination: destinationLocation,
        departureDate: startDate ? formatDate(startDate) : "",
        returnDate: endDate ? formatDate(endDate) : "",
        adults: 1,
        children: 0,
        infants: 0
    }

    searchFlights(searchData).then((res) => {
      setLoading(false);
      if(res.data) {
        setSearchData(res.data);
      }
    })
  }

  useEffect(() => {
    search();
  }, [originLocation,originLocation,destinationLocation,startDate,endDate]);

  useEffect(() => {
    search();
  }, []);


  return (
      <div className="nc-PageHome relative overflow-hidden">
        {/* GLASSMOPHIN */}
        <BgGlassmorphism/>
        <HomeSearchBlock isSearchPage={true}/>
        <div className="container relative pt-14 mb-24 lg:mb-28">
          {/* SECTION HERO */}
          {/*<SectionHero isSearchPage={true} className="pt-10 lg:pt-16 lg:pb-16"/>*/}

          <SectionGridFilterCard loading={loading} searchData={searchData} originCity={originCity} destinationCity={destinationCity} className="pb-24 lg:pb-28" />

          {/*/!* SECTION 1 *!/*/}
          {/*<SectionSliderNewCategories*/}
          {/*    categories={DEMO_CATS}*/}
          {/*    uniqueClassName="PageHome_s1"*/}
          {/*/>*/}

          {/* SECTION2 */}
          {/*<SectionOurFeatures/>*/}

          {/*/!* SECTION *!/*/}
          {/*<div className="relative py-16">*/}
          {/*  <BackgroundSection />*/}
          {/*  <SectionGridFeaturePlaces />*/}
          {/*</div>*/}

          {/* SECTION */}
          {/*<SectionHowItWork/>*/}

          {/* SECTION 1 */}


          {/* SECTION */}
          {/*<SectionSubscribe2/>*/}

          {/* SECTION */}

          {/* SECTION */}
          {/*<SectionGridCategoryBox />*/}

          {/* SECTION */}




          {/*/!* SECTION *!/*/}
          {/*<SectionVideos />*/}

          {/* SECTION */}

        </div>
      </div>
  )
};

export default SearchPage;
