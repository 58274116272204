import React, { FC, useState } from "react";

export interface FlightCardProps {
  className?: string;
  defaultOpen?: boolean;
  data: any; // We can define a more specific type if desired
}

const FlightCard: FC<FlightCardProps> = ({
                                           className = "",
                                           data,
                                           defaultOpen = false,
                                         }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  // Extract necessary data from the flight offer
  const { price, itineraries, validatingAirlineCodes } = data;

  // Get the total price and currency
  const totalPrice = price.total;
  const currency = price.currency;

  // Get the airline code (assuming the first validating airline code)
  const airlineCode = validatingAirlineCodes[0];

  // Placeholder for airline name and logo
  const airlineName = airlineCode;
  const airlineLogo = `https://logo.clearbit.com/${airlineCode}.com`; // Example logo URL

  // Get flight segments
  const segments = itineraries[0].segments; // Assuming one itinerary
  const departureSegment = segments[0];
  const arrivalSegment = segments[segments.length - 1];

  const departureTime = departureSegment.departure.at;
  const arrivalTime = arrivalSegment.arrival.at;

  // Get the departure and arrival airports
  const departureAirport = departureSegment.departure.iataCode;
  const arrivalAirport = arrivalSegment.arrival.iataCode;

  // Get flight duration
  const duration = itineraries[0].duration; // e.g., 'PT16H10M'

  // Get number of stops
  const numberOfStops = segments.length - 1;

  // Get cabin class (from fareDetailsBySegment)
  const cabinClass =
      data.travelerPricings[0].fareDetailsBySegment[0].cabin || "Economy";

  // Format duration from ISO 8601 to human-readable format
  const formatDuration = (isoDuration : any) => {
    const regex = /PT(?:(\d+)H)?(?:(\d+)M)?/;
    const matches = isoDuration.match(regex);
    const hours = matches[1] ? `${matches[1]}h` : "";
    const minutes = matches[2] ? `${matches[2]}m` : "";
    return `${hours} ${minutes}`.trim();
  };

  // Format time to HH:MM
  const formatTime = (dateTime: any) => {
    return new Date(dateTime).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  // Format date to readable format
  const formatDate = (dateTime : any) => {
    return new Date(dateTime).toLocaleDateString([], {
      weekday: "long",
      month: "long",
      day: "numeric",
    });
  };

  const renderDetailTop = () => {
    return (
        <div>
          <div className="flex flex-col md:flex-row ">
            <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
              <img src={airlineLogo} className="w-10" alt={airlineName} />
            </div>
            <div className="flex my-5 md:my-0">
              <div className="flex-shrink-0 flex flex-col items-center py-2">
                <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
                <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
              </div>
              <div className="ml-4 space-y-10 text-sm">
                <div className="flex flex-col space-y-1">
                <span className="text-neutral-500 dark:text-neutral-400">
                  {formatDate(departureTime)} · {formatTime(departureTime)}
                </span>
                  <span className="font-semibold">{departureAirport}</span>
                </div>
                <div className="flex flex-col space-y-1">
                <span className="text-neutral-500 dark:text-neutral-400">
                  {formatDate(arrivalTime)} · {formatTime(arrivalTime)}
                </span>
                  <span className="font-semibold">{arrivalAirport}</span>
                </div>
              </div>
            </div>
            <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
            <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
              <li>Trip duration: {formatDuration(duration)}</li>
              <li>
                {airlineName} · {cabinClass} class
              </li>
            </ul>
          </div>
        </div>
    );
  };

  const renderDetail = () => {
    if (!isOpen) return null;
    return (
        <div className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
          {renderDetailTop()}
          {/* Additional details can be added here */}
        </div>
    );
  };

  return (
      <div
          className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
          dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
          data-nc-id="FlightCard"
      >
        <div
            className={`sm:pr-20 relative ${className}`}
            data-nc-id="FlightCard"
        >
          {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <a href="##" className="absolute inset-0" />

          <span
              className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
                  isOpen ? "transform -rotate-180" : ""
              }`}
              onClick={() => setIsOpen(!isOpen)}
          >
          <i className="text-xl las la-angle-down"></i>
        </span>

          <div className="flex flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
            {/* LOGO IMG */}
            <div className="w-24 lg:w-32 flex-shrink-0">
              <img src={airlineLogo} className="w-10" alt={airlineName} />
            </div>

            {/* FOR MOBILE RESPONSIVE */}
            <div className="block lg:hidden space-y-1">
              <div className="flex font-semibold">
                <div>
                  <span>{formatTime(departureTime)}</span>
                  <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  {departureAirport}
                </span>
                </div>
                <span className="w-12 flex justify-center">
                <i className="text-2xl las la-long-arrow-alt-right"></i>
              </span>
                <div>
                  <span>{formatTime(arrivalTime)}</span>
                  <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  {arrivalAirport}
                </span>
                </div>
              </div>

              <div className="text-sm text-neutral-500 font-normal mt-0.5">
              <span>
                {numberOfStops
                    ? `${numberOfStops} stop${numberOfStops > 1 ? "s" : ""}`
                    : "Nonstop"}
              </span>
                <span className="mx-2">·</span>
                <span>{formatDuration(duration)}</span>
              </div>
            </div>

            {/* TIME - NAME */}
            <div className="hidden lg:block min-w-[150px] flex-[4]">
              <div className="font-medium text-lg">
                {formatTime(departureTime)} - {formatTime(arrivalTime)}
              </div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
                {airlineName}
              </div>
            </div>

            {/* ROUTE */}
            <div className="hidden lg:block flex-[4] whitespace-nowrap">
              <div className="font-medium text-lg">
                {departureAirport} - {arrivalAirport}
              </div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
                {formatDuration(duration)}
              </div>
            </div>

            {/* STOPS */}
            <div className="hidden lg:block flex-[4] whitespace-nowrap">
              <div className="font-medium text-lg">
                {numberOfStops
                    ? `${numberOfStops} stop${numberOfStops > 1 ? "s" : ""}`
                    : "Nonstop"}
              </div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
                {segments.length > 1
                    ? segments
                        .map((segment: any) => segment.departure.iataCode)
                        .join(" - ")
                    : null}
              </div>
            </div>

            {/* PRICE */}
            <div className="flex-[4] whitespace-nowrap sm:text-right">
              <div>
              <span className="text-xl font-semibold text-secondary-6000">
                {currency} {totalPrice}
              </span>
              </div>
              <div className="text-xs sm:text-sm text-neutral-500 font-normal mt-0.5">
                Total price
              </div>
            </div>
          </div>
        </div>

        {/* DETAIL */}
        {renderDetail()}
      </div>
  );
};

export default FlightCard;
