import React, {FC} from "react";

import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import searchBg from 'assets/images/search_bg.png';

export interface SectionHeroProps {
    className?: string;
    isSearchPage?: boolean;
}

const HomeSearchBlock: FC<SectionHeroProps> = ({className = "", isSearchPage = false}) => {

    return (
        <div
            style={{
                backgroundImage: `url(${searchBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
            className={`nc-SectionHero flex flex-col justify-center lg:flex-col relative ${
                isSearchPage ? "h-[450px] pb-[100px]" : "h-[700px]"
            } hidden lg:flex ${className}`}
            data-nc-id="SectionHero"
        >
            <div className=" flex flex-col items-center justify-center text-center text-white z-10">
                <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold leading-tight">
                    Zboară inteligent, plătește mai puțin.
                </h2>
                <p className="mt-4 text-sm sm:text-lg text-gray-200">
                    Explorați destinații populare la prețuri accesibile cu HiFly
                </p>
            </div>

            <div className="hidden lg:flex justify-center z-10 mb-12 lg:mb-0 lg:-mt-40 w-full mx-auto"
                 style={isSearchPage ? {marginTop: 20, marginBottom: -80} : {marginTop: 0}}>
                <HeroSearchForm/>
            </div>
        </div>
    );
};

export default HomeSearchBlock;
