import React, { useState, useEffect, useRef, FC } from "react";
import { MapPinIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { searchCities } from "../../services/search.service";

interface Props {
  onClick?: () => void;
  onChange?: (value: any) => void;
  className?: string;
  defaultValue?: string;
  headingText?: string;
}

const LocationInput: FC<Props> = ({
                                    onChange = () => {},
                                    className = "",
                                    defaultValue,
                                    headingText = "Where to?",
                                  }) => {
  const [value, setValue] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef(null);
  const inputRef = useRef(null);


  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setValue(newValue);
    // onChange && onChange(newValue);

    if (newValue.length > 2) {
      setIsLoading(true);
      const response = await searchCities(newValue);
      setSearchResults(response?.data || []);
      setIsLoading(false);
    } else {
      setSearchResults([]);
    }
  };

  const handleSelectLocation = (cityName: string, airportName: string) => {
    const location = `${cityName} + ${airportName}`;
    setValue(location);
    onChange && onChange({
        aerportId: airportName,
        cityName: cityName,
    });

    setSearchResults([]);
  };

  const renderSearchResults = () => {
    return searchResults.map((result) => {
      if (result?.airports?.length === 0) return null;
      return (
          <div key={result?.city?.id} className="px-4 py-2">
            <div className="flex items-center space-x-2 font-medium text-neutral-700 dark:text-neutral-200">
              <MapPinIcon className="w-5 h-5 text-blue-500" />
              <span>[{result?.city?.address.countryCode}] {result?.city?.name}</span>
            </div>
            <div className="pl-8 mt-2">
              {result?.airports?.map((airport: any) => (
                  <div
                      key={airport.id}
                      onClick={() => handleSelectLocation(result?.city?.name, airport.iataCode)}
                      className="flex items-center space-x-2 cursor-pointer hover:bg-neutral-100 dark:hover:bg-neutral-700 px-2 py-1 rounded"
                  >
                    <MapPinIcon className="w-4 h-4 text-neutral-400" />
                    <span className="text-sm text-neutral-600 dark:text-neutral-400">
                  {airport.name} ({airport.iataCode})
                </span>
                  </div>
              ))}
            </div>
          </div>
      );
    });
  };

  return (
      <div className={`${className}`} ref={containerRef}>
        <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {headingText}
        </span>
          <div className="relative mt-5">
            <input
                className="block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold"
                placeholder="Search destinations"
                value={value}
                onChange={handleInputChange}
                ref={inputRef}
            />
            <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
          </div>
          <div className="mt-7">
            {isLoading ? (
                <p className="text-center">Loading...</p>
            ) : searchResults.length > 0 ? (
                renderSearchResults()
            ) : (
                <p className="text-center">No results found</p>
            )}
          </div>
        </div>
      </div>
  );
};

export default LocationInput;
