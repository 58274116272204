import DatePicker from "react-datepicker";
import React, {FC, useState} from "react";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";

export interface StayDatesRangeInputProps {
    className?: string,
    onChange?: (range: any) => void
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
                                                               className = "",
                                                               onChange
                                                           }) => {
    const [startDate, setStartDate] = useState<Date | null>(
        new Date(new Date())
    );
    const [endDate, setEndDate] = useState<Date | null>(null);

    const onChangeDate = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        onChange && onChange(dates);
    };

    return (
        <div>
            <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {` When's your trip?`}
        </span>
            </div>
            <div
                className={`relative flex-shrink-0 flex justify-center z-10 py-5 ${className} `}
            >
                <DatePicker
                    selected={startDate}
                    onChange={onChangeDate}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    monthsShown={2}
                    showPopperArrow={false}
                    minDate={new Date()}
                    inline
                    renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
                    renderDayContents={(day, date) => (
                        <DatePickerCustomDay dayOfMonth={day} date={date}/>
                    )}
                />
            </div>
        </div>
    );
};

export default StayDatesRangeInput;
