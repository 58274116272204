import React, { FC } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import {SignUp} from "@clerk/clerk-react";

export interface PageSignUpProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || Booking React Template</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">

          <SignUp
              signInUrl={"/auth/register"}
              appearance={{
                elements: {
                  rootBox: 'flex flex-col items-center justify-center w-full',
                  card: 'w-full max-w-full bg-white p-6 rounded-lg shadow-md',
                  formButtonPrimary: 'bg-blue-600 hover:bg-blue-700 text-white font-medium w-full',
                  formFieldInput: 'border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200',
                  formDividerText: 'text-gray-500',
                  formFieldErrorText: 'text-red-500',
                  footerActionLink: 'text-blue-600 hover:underline',
                },
              }}
          />
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
