import React, { FC } from "react";
import { Helmet } from "react-helmet-async";

import { SignIn } from '@clerk/clerk-react'

export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login </title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">


          <SignIn
              path={"/login"}
              routing="path"
              appearance={{
                elements: {
                  rootBox: 'flex flex-col items-center justify-center w-full',
                  card: 'w-full max-w-full bg-white p-6 rounded-lg shadow-md',
                  formButtonPrimary: 'bg-blue-600 hover:bg-blue-700 text-white font-medium w-full',
                  formFieldInput: 'border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200',
                  formDividerText: 'text-gray-500',
                  formFieldErrorText: 'text-red-500',
                  footerActionLink: 'text-blue-600 hover:underline',
                },
              }}
              signUpUrl={"/register"}/>

        </div>
      </div>
    </div>
  );
};

export default PageLogin;
