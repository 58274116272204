// export const API_URL = 'https://admin.motoland.md/api';
export const APP_URL_STORAGE = 'https://admin.motoland.md/storage';
// export const APP_URL_STORAGE = 'http://localhost:8000/storage';
export const API_URL = 'https://admin.hifly.md/api';
// export const API_URL = 'http://127.0.0.1:8000/api';
export const BASE_URL = 'https://motoland.md';


// export const API_URL = typeof window === 'undefined' ? 'http://127.0.0.1:9001/api' : 'https://admin.motoland.md/api';

export const JWT_SECRET = 'yrzVYTeCzKbOBrbPDclIsFUf962n5OgPpIqbIRIeDXh8dhsxip0ZJAy9mGTa2LdV';
