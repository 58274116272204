import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import LangDropdown from "./LangDropdown";
import NotifyDropdown from "./NotifyDropdown";
import AvatarDropdown from "./AvatarDropdown";
import CurrencyDropdown from "./CurrencyDropdown";
import DropdownTravelers from "./DropdownTravelers";
import { Link } from "react-router-dom";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import HeroSearchForm2Mobile from "../HeroSearchForm2Mobile/HeroSearchForm2Mobile";

export interface MainNav2Props {
  className?: string;
}

const MainNav2: FC<MainNav2Props> = ({ className = "" }) => {
  return (
    <div className={`nc-MainNav1 nc-MainNav2 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <Link to={"/"}><h1 style={{ fontSize: 30 }}>Hi-Fly</h1></Link>
          <div className="hidden lg:block h-10 border-l border-neutral-300 dark:border-neutral-500"></div>
          <div className="hidden lg:block">
            <DropdownTravelers />
          </div>
        </div>

        <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <HeroSearchForm2Mobile />
        </div>

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden items-center lg:flex space-x-1">
            <CurrencyDropdown />
            <LangDropdown />
            {/*<Link*/}
            {/*  to="/add-listing-1"*/}
            {/*  className="*/}
            {/*    text-opacity-90*/}
            {/*    group px-4 py-2 border border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"*/}
            {/*>*/}
            {/*  List your property*/}
            {/*</Link>*/}

            <div></div>
            {/*<SwitchDarkMode />*/}
            {/*<div className="pr-1.5">*/}
            {/*  <NotifyDropdown className="-ml-2 xl:-ml-1" />*/}
            {/*</div>*/}
            <ButtonPrimary href="/login">Sign in</ButtonPrimary>
          </div>
          <div className="flex items-center space-x-2 lg:hidden">
            {/*<NotifyDropdown />*/}
            <AvatarDropdown />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav2;
