import React, {useEffect, useState} from "react";
import LocationInput from "../LocationInput";
import GuestsInput from "../GuestsInput";
import DatesRangeInput from "../DatesRangeInput";
import { GuestsObject } from "components/HeroSearchForm/type";
import converSelectedDateToString from "utils/converSelectedDateToString";

type Props = {
    onUrlSearchSet: (url: string) => void;
};

const FlightSearchForm: React.FC<Props> = ({onUrlSearchSet}) => {
  const [fieldNameShow, setFieldNameShow] = useState<
      "locationPickup" | "locationDropoff" | "dates" | "guests" | "general"
  >("locationPickup");
  const [locationInputPickUp, setLocationInputPickUp] = useState("");
  const [locationInputDropOff, setLocationInputDropOff] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [guestInput, setGuestInput] = useState<GuestsObject>({
    guestAdults: 1,
    guestChildren: 0,
    guestInfants: 0,
  });

    const [originLocation, setOriginLocation] = useState<any>(null);
    const [destinationLocation, setDestinationLocation] = useState<any>(null);


    const buildSearchUrl = () => {
        if (!originLocation || !destinationLocation || !startDate) {
            return "";
        }
        //add and city name in url
        const originCityName = originLocation?.cityName;
        const destinationCityName = destinationLocation?.cityName;

        const originAeroportId = originLocation?.aerportId;
        const destinationAeroportId = destinationLocation?.aerportId;
        const start_date = startDate?.toLocaleDateString("en-US") || "2023-05-01";
        const end_date = endDate?.toLocaleDateString("en-US") || "2023-05-16";
        const search_url = `/search?originLocation=${originAeroportId}&originCity=${originCityName}&destinationLocation=${destinationAeroportId}&destinationCity=${destinationCityName}&startDate=${start_date}&endDate=${end_date}`;
        console.log("ddd",search_url)
        onUrlSearchSet(search_url);
    }


    useEffect(() => {
        buildSearchUrl();
    }, [locationInputPickUp, locationInputDropOff, startDate, endDate, guestInput]);


  const renderInputLocationPickup = () => {
    const isActive = fieldNameShow === "locationPickup";
    return (
        <div
            className={`w-full ${
                isActive ? "rounded-2xl shadow-lg" : "rounded-xl shadow-sm"
            }`}
        >
          {!isActive ? (
              <button
                  className="w-full flex justify-between text-sm font-medium p-4"
                  onClick={() => setFieldNameShow("locationPickup")}
              >
                <span className="text-neutral-400">Pick up</span>
                <span>{locationInputPickUp || "Select location"}</span>
              </button>
          ) : (
              <LocationInput
                  onChange={(value) => {
                      setLocationInputPickUp(`${value.cityName} + ${value.aerportId}`)
                      setOriginLocation(value);
                    setFieldNameShow("locationDropoff");
                  }}
              />
          )}
        </div>
    );
  };

  const renderInputLocationDropoff = () => {
    const isActive = fieldNameShow === "locationDropoff";
    return (
        <div
            className={`w-full ${
                isActive ? "rounded-2xl shadow-lg" : "rounded-xl shadow-sm"
            }`}
        >
          {!isActive ? (
              <button
                  className="w-full flex justify-between text-sm font-medium p-4"
                  onClick={() => setFieldNameShow("locationDropoff")}
              >
                <span className="text-neutral-400">Drop off</span>
                <span>{locationInputDropOff || "Select location"}</span>
              </button>
          ) : (
              <LocationInput
                  onChange={(value) => {
                      setLocationInputDropOff(`${value.cityName} + ${value.aerportId}`);
                    setDestinationLocation(value);
                    setFieldNameShow("dates");
                  }}
              />
          )}
        </div>
    );
  };

  const renderInputDates = () => {
    const isActive = fieldNameShow === "dates";
    return (
        <div
            className={`w-full ${
                isActive ? "rounded-2xl shadow-lg" : "rounded-xl shadow-sm"
            }`}
        >
          {!isActive ? (
              <button
                  className="w-full flex justify-between text-sm font-medium p-4"
                  onClick={() => setFieldNameShow("dates")}
              >
                <span className="text-neutral-400">When</span>
                <span>
              {startDate
                  ? converSelectedDateToString([startDate, endDate])
                  : "Add date"}
            </span>
              </button>
          ) : (
              <DatesRangeInput
                  onChange={(range) => {
                    setStartDate(range[0]);
                    setEndDate(range[1]);
                    setFieldNameShow("guests");
                  }}
              />
          )}
        </div>
    );
  };

  const renderInputGuests = () => {
    const isActive = fieldNameShow === "guests";
    let guestSummary = "";
    if (guestInput.guestAdults || guestInput.guestChildren) {
      const totalGuests =
          (guestInput.guestAdults || 0) + (guestInput.guestChildren || 0);
      guestSummary += `${totalGuests} guests`;
    }
    if (guestInput.guestInfants) {
      guestSummary += `, ${guestInput.guestInfants} infants`;
    }

    return (
        <div
            className={`w-full ${
                isActive ? "rounded-2xl shadow-lg" : "rounded-xl shadow-sm"
            }`}
        >
          {!isActive ? (
              <button
                  className="w-full flex justify-between text-sm font-medium p-4"
                  onClick={() => setFieldNameShow("guests")}
              >
                <span className="text-neutral-400">Who</span>
                <span>{guestSummary || "Add guests"}</span>
              </button>
          ) : (
              <GuestsInput
                  defaultValue={guestInput}
                  onChange={(value) => {
                    setGuestInput(value);
                    setFieldNameShow("general");
                  }}
              />
          )}
        </div>
    );
  };

  const renderGeneral = () => (
      <div className="w-full rounded-2xl shadow-lg">
        <button
            className="w-full flex justify-between text-sm font-medium p-4"
            onClick={() => setFieldNameShow("general")}
        >
          <span className="text-neutral-400">Search Flight</span>
        </button>
      </div>
  );

  return (
      <div className="space-y-5">
        {renderInputLocationPickup()}
        {renderInputLocationDropoff()}
        {renderInputDates()}
        {renderInputGuests()}
        {renderGeneral()}
      </div>
  );
};

export default FlightSearchForm;
