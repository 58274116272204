import Label from "components/Label/Label";
import React, { FC } from "react";
import { UserProfile } from '@clerk/clerk-react'
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account || Booking React Template</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Account infomation</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">

            <UserProfile/>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
