import React, { FC } from "react";
import LangDropdown from "./LangDropdown";
import CurrencyDropdown from "./CurrencyDropdown";
import DropdownTravelers from "./DropdownTravelers";
import { Link } from "react-router-dom";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import HeroSearchForm2Mobile from "../HeroSearchForm2Mobile/HeroSearchForm2Mobile";
import {useAuth, UserButton} from "@clerk/clerk-react";
import logo  from 'assets/images/logo.png';
import SwitchDarkMode from "../../shared/SwitchDarkMode/SwitchDarkMode";
import MenuBar from "../../shared/MenuBar/MenuBar";

export interface MainNav2Props {
  className?: string;
}

const MainNav2: FC<MainNav2Props> = ({ className = "" }) => {

  const { isSignedIn } = useAuth()

  return (
    <div className={`nc-MainNav1 nc-MainNav2 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between w-full items-center">
        <div className="flex justify-start flex-1 items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <Link to={"/"}>
            <img src={logo}/>
          </Link>
          <div className="lg:block h-10 border-l border-neutral-300 dark:border-neutral-500"></div>
          <div className="lg:block">
            <DropdownTravelers />
          </div>
        </div>

        <div className="lg:hidden absolute mt-[150px] w-[93%]">
          <HeroSearchForm2Mobile />
        </div>

        <div className="md:flex flex-shrink-0 items-center justify-end lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden items-center lg:flex space-x-1">
            <CurrencyDropdown/>
            <LangDropdown/>
            <SwitchDarkMode/>

            {
              isSignedIn ? <UserButton/> : <ButtonPrimary href="/login">Sign in</ButtonPrimary>
            }

          </div>
          <div className="flex xl:hidden items-center">
            <SwitchDarkMode/>
            <div className="px-0.5"/>
            <MenuBar/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav2;
