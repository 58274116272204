import axios from "axios";

export const searchCities = async (query: string) => {
    return axios.get(`/search-cities?keyword=${query}&include=AIRPORTS`,)
        .then(res => {
            return res;
        })
        .catch(e =>{
            return e;
        })
}


export const searchFlights = async (searchData: any) => {
    return axios.post(`/search-flights`, searchData)
        .then(res => {
            return res;
        })
        .catch(e =>{
            return e;
        })
}
