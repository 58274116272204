// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading="lazy"] {
    clip-path: inset(0.6px);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ListingImageGallery/styles/index.css"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAuB;EACzB;AACF","sourcesContent":["@supports (font: -apple-system-body) and (-webkit-appearance: none) {\n  img[loading=\"lazy\"] {\n    clip-path: inset(0.6px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
