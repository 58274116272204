import axios, { AxiosResponse } from "axios";
import {toast} from "react-toastify";

import { API_URL } from "./config";

export const configureAxiosErrorHandler = () => {

    axios.defaults.baseURL = API_URL;

    // axios.interceptors.request.use(request => {
    //   console.log('Starting Request', JSON.stringify(request, null, 2))
    //   return request
    // })

    axios.interceptors.response.use(undefined, error => {

        // if (error.message === 'Network Error' && !error.response) {
        //     toast.error('Connection to the server lost. Reconnecting...');
        // }

        // const { status, data, config } = error.response;
        //
        // if (status === 404) {
        //     toast.error('Error 404');
        // }
        //
        // if (status === 402) {
        //     toast.error('Error 404');
        // }
        //
        // if (status === 410) {
        //     toast.error('Error 404');
        // }
        //
        // if (status === 403) {
        //     toast.error('Error 404');
        // }
        //
        // if (status === 500) {
        //     toast.error('Error 404');
        // }
        //
        // if (status === 400) {
        //     toast.error('Error 404');
        // }
        //
        // if (status === 401) {
        //     toast.error('Your session expired. Please login.');
        // }


        return Promise.reject(error);
    });
}
